<template>
  <div class="application-wrapper" :style="platformMargin">
    <InfoToast />
    <SearchPageWrapper v-if="authenticated" />
    <LoadingSpinner v-else-if="tokensInStorage && !workspacesEmpty" />
    <WorkspacesEmpty v-else-if="workspacesEmpty" />
    <UmanLogin v-else />
    <div v-if="isDev" class="dev-indicator">DEV</div>
  </div>
</template>

<script>
import UmanLogin from "./auth/UmanLogin.vue";
import SearchPageWrapper from "./search/SearchPageWrapper.vue";
import { mapGetters, mapState } from "vuex";
import jwt_decode from "jwt-decode";
import LoadingSpinner from "./loading/LoadingSpinner.vue";
import WorkspacesEmpty from "./error/WorkspacesEmpty.vue";
import InfoToast from "./general/InfoToast.vue";

export default {
  name: "ApplicationWrapper",
  components: {
    UmanLogin,
    SearchPageWrapper,
    LoadingSpinner,
    WorkspacesEmpty,
    InfoToast,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      workspacesEmpty: (state) => state.workspaces.workspacesEmpty,
    }),
    ...mapGetters(["authenticated", "tokensInStorage"]),
    platformMargin() {
      const topMarginNeeded = ["OfficeOnline"];
      return topMarginNeeded.includes(
        window.Office.context.diagnostics.platform
      )
        ? "--platform-margin: 1rem"
        : "--platform-margin: 0";
    },
    isDev() {
      return process.env.NODE_ENV === "development";
    },
  },
  async mounted() {
    // Uncomment when switching api during development
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("refreshToken");
    const access = localStorage.getItem("accessToken");
    const refresh = localStorage.getItem("refreshToken");

    const invalidTokens = [undefined, "undefined", null, "null"];
    if (
      !(invalidTokens.includes(access) || invalidTokens.includes(refresh)) &&
      (jwt_decode(refresh).exp || 0) * 1000 > Date.now()
    ) {
      this.$store.dispatch("login", {
        access,
        refresh,
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.application-wrapper {
  display: flex;
  flex-flow: column nowrap;

  height: 100%;
  width: 100%;
  box-sizing: border-box;

  align-items: center;
  background: white;

  margin-top: var(--platform-margin);
}

.dev-indicator {
  position: absolute;
  top: 0;
  left: 0;
  background: #115efb;
  color: white;
  font-weight: 700;
  padding: 0.2rem 0.75rem;
}
</style>
