import Console from "@/console";
import ISO6391 from "iso-639-1";
import { getClient, getResponseData } from "../axios";
import { Paginator } from "../paginator";

export default {
  state: {
    subresources: [],
    searchQuery: "",
    searchingByQuery: false,
    lastSearchId: undefined,
    labels: [],
    bookmarks: 0,
  },
  getters: {
    lastSearchId: (state) => state.lastSearchId,
    labels: (state) => state.labels,
    bookmarks: (state) => state.bookmarks,
  },
  mutations: {
    upsertSubresources(state, data) {
      let pushFront = data ? data.pushFront : false;
      const subresources =
        (data || { subresources: undefined }).subresources || data;
      // Append or refresh
      for (const subresource of subresources) {
        const index = state.subresources.findIndex(
          (x) => x.uuid === subresource.uuid
        );
        if (index === -1) {
          if (pushFront) {
            state.subresources.unshift(subresource);
          } else {
            state.subresources.push(subresource);
          }
        } else {
          const upsertedSubresource = {
            ...state.subresources[index],
            ...subresource,
          };
          state.subresources[index] = upsertedSubresource;
        }
      }
    },
    setSearchQuery(state, query) {
      state.searchQuery = query;
    },
    setSearchingByQuery(state, searching) {
      state.searchingByQuery = searching;
    },
    setLastSearchId(state, id) {
      state.lastSearchId = id;
    },
    setLabels(state, labels) {
      state.labels = labels;
    },
    setBookmarks(state, bookmarks) {
      state.bookmarks = bookmarks;
    },
  },
  actions: {
    async getSearchResourcesPaginator(
      { commit },
      { data, params, noCommit, extraData, extraParams, documentType }
    ) {
      if (!documentType) return;
      const searchData = {
        relevancy: "medium",
        ...data,
      };
      const emptyExtraParams = { ...params };
      Object.keys(extraParams).forEach(
        (x) => (emptyExtraParams[x] = undefined)
      );
      const extraParamsCopy = Object.assign({}, extraParams);
      delete extraParamsCopy.workspace;

      if (searchData.language) {
        for (let i = 0; i < searchData.language.length; i++) {
          let isoCode = ISO6391.getCode(searchData.language[i]);
          if (searchData.language[i] === "Chinese") {
            isoCode = "zh-cn";
          }
          searchData.language[i] = isoCode;
        }
      }
      let resourceLevel =
        documentType === "slides" ? "subresources" : "resources";

      if (documentType === "snippets") {
        resourceLevel = "snippets";
      }

      const client = getClient("/workspaces");

      const response = await client.post(
        `/${extraParams.workspace}/${resourceLevel}/search/`,
        { ...searchData, ...extraData, tab: documentType },
        { params: { ...params, ...extraParamsCopy }, timeout: 10000 }
      );
      if (documentType === "people") {
        delete response["data"]["results"]["content_classes"];
      }

      const secondaryData = getResponseData(response);

      commit("setLabels", secondaryData?.labels || []);
      commit("setBookmarks", secondaryData?.bookmarks || 0);

      return new Paginator(
        client,
        response,
        (data) => {
          if (!noCommit) {
            const mutation =
              resourceLevel === "resources"
                ? "upsertResources"
                : "upsertSubresources";
            commit(mutation, data);
          }
        },
        resourceLevel,
        async (url) => {
          let { detail, ...dataRest } = searchData;
          if (detail)
            Console.debug(`Removing detail ${detail} from next page call`);
          return client.post(
            url,
            { ...dataRest, trigger: "scroll", tab: documentType },
            {
              params: { ...emptyExtraParams },
              timeout: 10000,
            }
          );
        }
      );
    },
    async getSlideURL(_, { workspace, slide }) {
      const client = getClient("/workspaces");
      const response = await client.get(
        `/${workspace}/subresources/${slide}/content/`
      );
      return getResponseData(response);
    },
  },
  modules: {},
};
