<template>
  <div class="labels">
    <div v-for="label in slide.labels" :key="label.uuid" class="label">
      <img
        :src="require(`@a/icons/qualitylabels/border/${label.type}.svg`)"
        alt=""
        class="icon"
        @mouseenter="labelHovered = label.uuid"
        @mouseleave="labelHovered = undefined"
      />
      <div v-if="labelHovered === label.uuid" class="tooltip">
        {{ label.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QualityLabels",
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    labelHovered: undefined,
  }),
};
</script>

<style scoped lang="scss">
.labels {
  padding: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  gap: 0.2rem;

  .label {
    position: relative;
  }

  .icon {
    height: 2rem;
    cursor: default;
  }

  .tooltip {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    background: #2b2b2b;
    color: white;
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    white-space: nowrap;

    &::before {
      content: "";
      height: 0.3rem;
      width: 0.5rem;
      background: #2b2b2b;
      position: absolute;
      bottom: -0.3rem;
      left: 0.7rem;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
}
</style>
