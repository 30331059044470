import { getResponseData } from "./axios";

export function getResponseCount(response) {
  if (response.data instanceof Object && "count" in response.data) {
    return response.data.count;
  }

  return NaN;
}

export class Paginator {
  /**
   * Object for handling pagination via its pages() method.
   * Can also return all pages (up to maxPage) via its all() method.
   * Paginated key is when there is more information returned than the amount that is paginated.
   * This key will be used to index data that has the paginated behaviour.
   * The nextPageFetch is a function that takes in an url and fetches this with the required data.
   * This can be used if the pagination is over a post endpoint and the POST data needs to be sent as well.
   */
  constructor(
    client,
    promise,
    callback,
    paginatedKey = undefined,
    nextPageFetch = undefined
  ) {
    this.client = client;
    this.promise = promise;
    this.callback = callback;
    this._count = NaN;
    this.paginatedKey = paginatedKey;
    this.nextPageFetch = nextPageFetch;

    this.cachedPages = [];
  }

  transformData(data) {
    return this.paginatedKey ? data[this.paginatedKey] : data;
  }

  async count() {
    if (!isNaN(this._count)) {
      return this._count;
    }
    const pages = await this.pages();
    await pages.next();
    return this._count;
  }

  async *pages() {
    /**
     * Generator giving one page of results per call to next().
     * When done is true, no data will be included
     */

    let page = 1;
    let res = null;
    const hasNext = (res) => "next" in res.data && res.data.next != null;

    // check if initial request has already been cached
    if (this.cachedPages.length > 0) {
      res = this.cachedPages[0];
    } else {
      res = await this.promise;
      this.cachedPages[0] = res;
    }
    this._count = getResponseCount(res);
    const actualResult = this.transformData(getResponseData(res));
    if (this.callback) {
      this.callback(actualResult);
    }
    yield {
      data: actualResult,
      rawData: res.data.results,
      next: hasNext(res),
    };
    page++;

    while (hasNext(res)) {
      // check if current page has been cached, else execute
      if (this.cachedPages.length >= page) {
        res = this.cachedPages[page - 1];
      } else {
        const url = res.data["next"];
        if (this.nextPageFetch) {
          res = await this.nextPageFetch(url);
        } else {
          res = await this.client.get(url);
        }
        this.cachedPages[page - 1] = res;
      }

      const actualResult = this.transformData(getResponseData(res));

      if (this.callback) {
        this.callback(actualResult);
      }
      yield {
        data: actualResult,
        rawData: res.data.results,
        next: hasNext(res),
      };
      page++;
    }
  }

  async all(maxPage = 999) {
    const pages = await this.pages();
    let data = null;

    for (let i = 0; i < maxPage; i++) {
      const { value, done } = await pages.next();

      if (done) {
        break;
      }

      const resData = value.data;
      // data in Array form
      if (resData instanceof Array) {
        if (data == null) {
          data = [];
        }
        data.push(...resData);
        // data in Object form
      } else if (resData instanceof Object) {
        if (data == null) {
          data = {};
        }
        data = { ...data, ...resData };
      }
    }

    return data;
  }
}
