import { createStore } from "vuex";

import authentication from "./modules/authentication.js";
import resources from "./modules/resources.js";
import workspaces from "./modules/workspaces.js";
import addin from "./modules/addin.js";

export default createStore({
  state: {},
  modules: {
    authentication,
    resources,
    workspaces,
    addin,
  },
  getters: {},
  mutations: {},
  actions: {},
});
