<template>
  <div class="loading">
    <img src="@a/icons/spinner.svg" alt="" class="loading-img" />
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style scoped lang="scss">
.loading {
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &-img {
    height: 3rem;
    animation: spin 500ms linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(330deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
