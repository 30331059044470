<template>
  <div class="switch">
    <input
      type="checkbox"
      id="switch"
      @click="handleClick()"
      :value="toggled"
      :checked="toggled"
    />
    <label for="switch">Toggle</label>
  </div>
</template>

<script>
export default {
  name: "SwitchToggle",
  props: ["toggled"],
  methods: {
    handleClick() {
      this.$emit("update:toggled", !this.toggled);
    },
  },
};
</script>

<style scoped lang="scss">
.switch {
  width: 32px;
  height: 16px;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 32px;
    height: 16px;
    background: #8f9399;
    display: block;
    border-radius: 999rem;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 999rem;
    transition: all 300ms ease;
  }

  input:checked + label {
    background: #24ce85;
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}
</style>
