<template>
  <div class="searchbar">
    <div class="searchbar-icon search">
      <img src="@a/icons/search.svg" alt="" class="searchbar-icon-img" />
    </div>
    <input
      v-model="query"
      type="text"
      name="search"
      id="searchbar"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :placeholder="searchbarPlaceholder"
      :class="{
        'searchbar-input': true,
        'searchbar-clearable': query,
      }"
      @keydown.enter="search"
    />
    <div v-if="query" class="searchbar-icon clear" @click="handleClear">
      <img src="@a/icons/close_filled.svg" alt="" class="searchbar-icon-img" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "SearchBar",
  data: () => ({
    query: "",
  }),
  computed: {
    ...mapState({
      searchQuery: (state) => state.resources.searchQuery,
      currentWorkspace: (state) => state.workspaces.currentWorkspace,
      searchingByQuery: (state) => state.workspaces.searchingByQuery,
    }),
    searchbarPlaceholder() {
      return `Search in ${this.currentWorkspace.name}`;
    },
  },
  methods: {
    ...mapMutations(["setSearchQuery", "setSearchingByQuery"]),
    search() {
      this.setSearchingByQuery(this.query !== "");
      this.setSearchQuery(this.query);
    },
    handleClear() {
      this.query = "";
      this.search();
    },
  },
  watch: {
    searchQuery(newValue) {
      if (this.query !== newValue && this.searchingByQuery) {
        this.query = newValue;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.searchbar {
  position: relative;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  &-input {
    height: 2rem;
    padding: 0 2rem; // Padding for icons
    width: 100%;
    box-sizing: border-box;

    border: 2px solid #ffffff00;
    border-radius: 180px !important;

    background-color: #f1f2f3;
    transition: all 0.3s ease-in-out;

    font-size: 1rem;

    &:hover {
      background-color: #f5f6f8;
    }

    &:focus,
    :focus-visible,
    :focus-within {
      outline: none;
      border: 2px solid #115efb;
      background-color: #ffffff;
    }

    &[placeholder] {
      text-overflow: ellipsis;
    }
  }

  &-icon {
    position: absolute;
    top: 0;

    height: 2rem;
    width: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      width: 1rem;
      filter: invert(65%) sepia(1%) saturate(1962%) hue-rotate(177deg)
        brightness(88%) contrast(106%);
    }

    &.search {
      left: 1rem;
    }

    &.clear {
      right: 1rem;
      cursor: pointer;
      opacity: 48%;
    }
  }
}
</style>
