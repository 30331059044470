<template>
  <div class="search">
    <SearchPageHeader class="search-header" />
    <div
      ref="results"
      v-if="searchResultsLoaded"
      class="search-results"
      @wheel.passive="handleResultsScroll"
    >
      <SearchResultHeader
        :polling-enabled="pollingEnabled"
        :header="searchResultHeader"
        @pollingChange="(e) => (pollingEnabled = e)"
        @refresh="refresh()"
      />
      <PillFilters
        :activeFilters="activeFilters"
        @toggleFilter="toggleFilter"
      />
      <div
        v-for="(slide, idx) in searchResultsLoaded ? paginatorData : []"
        :key="slide.uuid"
        class="result"
        @mouseenter.stop="slideHovered = slide.uuid"
        @mouseleave.stop="slideHovered = undefined"
      >
        <SlideVisualisation
          class="data-visualisation-scroll"
          :slide="slide"
          :rank="idx + 1"
          @load="slidesLoaded += 1"
          @error="slidesLoaded += 1"
        />
        <InfoButton
          v-if="slideHovered === slide.uuid"
          :slide="slide"
          :rank="idx + 1"
          class="result-info"
        />
      </div>
    </div>
    <LoadingSpinner v-else-if="!searchResultsLoaded && consumptionLoading" />
    <div
      v-else-if="
        !searchResultsLoaded &&
        !consumptionLoading &&
        paginatorData.length === 0
      "
    >
      <SearchResultHeader
        :polling-enabled="pollingEnabled"
        :header="searchResultHeader"
        @pollingChange="(e) => (pollingEnabled = e)"
        @refresh="refresh()"
      />
      <PillFilters
        :activeFilters="activeFilters"
        @toggleFilter="toggleFilter"
      />
      <SearchEmpty />
    </div>
  </div>
</template>

<script>
import { toRaw } from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SearchPageHeader from "./SearchPageHeader.vue";
import SlideVisualisation from "./slides/SlideVisualisation.vue";
import InfoButton from "./slides/InfoButton.vue";
import LoadingSpinner from "../loading/LoadingSpinner.vue";
import SearchEmpty from "./SearchEmpty.vue";
import IntercomMixin from "./mixins/IntercomMixin";
import SearchResultHeader from "./SearchResultHeader.vue";
import {
  sendBatchViewEvent,
  searchTrigger,
  searchDetail,
} from "@/store/services/feedbackService";
import PillFilters from "./PillFilters.vue";

export default {
  name: "SearchPageWrapper",
  mixins: [IntercomMixin],
  components: {
    SearchPageHeader,
    SlideVisualisation,
    InfoButton,
    LoadingSpinner,
    SearchEmpty,
    SearchResultHeader,
    PillFilters,
  },
  data: () => ({
    paginator: undefined,
    pages: undefined,
    paginatorData: [],
    pagesLoaded: 0,
    isDone: false,
    consumptionLoading: false,
    contextListener: undefined,
    slideTitle: "",
    slideIndex: 0,
    slideHovered: undefined,
    notTypingTicks: 0,
    notTypingTrigger: 5,
    pollingEnabled: false,
    slidesLoaded: 0,
    visualisationElements: [],
    scrollTimer: undefined,
    scrollIndex: 0,
    maxScrollY: 0,
    initialSlideLoad: true,
    searchTrigger: {},
    activeFilters: {},
  }),
  async mounted() {
    if (this.showPollingToggle) {
      const pollingEnabledSetting =
        window.Office.context.document.settings.get("pollingEnabled");
      if (typeof pollingEnabledSetting === "boolean") {
        this.pollingEnabled = pollingEnabledSetting;
        if (pollingEnabledSetting) {
          await this.initialContextCheck();
          this.contextListener = setInterval(this.checkSlideContext, 100);
        }
      }
    }
    await this.setupPaginatorConsumer();
  },
  unmounted() {
    this.contextListener = undefined;
  },
  computed: {
    ...mapState({
      workspace_id: (state) => state.workspaces.currentWorkspaceId,
      searchQuery: (state) => state.resources.searchQuery,
      searchingByQuery: (state) => state.resources.searchingByQuery,
      currentUser: (state) => state.authentication.currentUser,
      currentWorkspace: (state) => state.workspaces.currentWorkspace,
    }),
    ...mapGetters(["showPollingToggle"]),
    searchResultHeader() {
      return this.searchingByQuery
        ? "Search results"
        : this.searchQuery === ""
        ? "Workspace contents"
        : "Suggested slides";
    },
    searchResultsLoaded() {
      return this.pagesLoaded > 0 && this.paginatorData.length > 0;
    },
    allSlidesLoaded() {
      return this.slidesLoaded === this.paginatorData.length;
    },
  },
  methods: {
    ...mapActions([
      "getSearchResourcesPaginator",
      "setToast",
      "setPollingEnabled",
    ]),
    ...mapMutations(["setSearchQuery"]),
    async setupPaginatorConsumer() {
      try {
        if (this.consumptionLoading) return;
        this.consumptionLoading = true;
        this.paginator = await this.getSearchResourcesPaginator({
          ...this.getRequestData(),
        });
        this.pages = await this.getPages();
        await this.loadPage(true);
        this.paginatorDataCount = await this.getCount();
        this.consumptionLoading = false;
      } catch (e) {
        this.setToast({
          title: "Search error",
          subtitle:
            e.message === "Axios_timeout"
              ? "Timeout error, couldn't get data in time"
              : "Error, something went wrong when getting the data",
          type: "error",
        });
      }
    },
    getRequestData() {
      const searchEnrichment = {
        ...this.searchTrigger,
        page: "search_results",
      };
      this.searchTrigger = {};
      return {
        data: {
          act_as: undefined,
          sort_by:
            this.searchQuery === "" ? "modified_date_desc" : "score_desc",
          query: this.searchQuery,
          with_highlights: !!this.searchQuery,
          extract_entities: true,
          ...searchEnrichment,
          ...this.activeFilters,
        },
        extraParams: {
          page_size: 10,
          workspace: this.workspace_id,
          included_facets: [
            "topics",
            // "experts",
            // "document_types",
            // "content_classes",
            "language",
            "industries",
            "organisations",
            "created_date",
            "modified_date",
            "mimetypes",
            "labels",
            "bookmarks",
            "resolutions",
          ].join(" "),
        },
        documentType: "slides",
      };
    },
    async getPages() {
      return this.paginator.pages() || undefined;
    },
    async getCount() {
      return this.paginator.pages() || 0;
    },
    async loadPage(force = false) {
      if (this.isDone) return;
      if (!this.pages) this.pages = await this.getPages();
      if (this.consumptionLoading && !force) return;

      this.consumptionLoading = true;
      if (!this.pages) return;
      const data = await this.pages.next();
      if (!(data && data.value && data.value.data)) {
        this.consumptionLoading = false;
        this.isDone = true;
        this.setToast({
          title: "No data could be found",
          subtitle: "We couldn't find any data, something's wrong",
          type: "error",
        });
        return;
      }
      this.pagesLoaded += 1;
      const isDone = data.done || !data.value.next;
      if (isDone) {
        this.paginatorData = [
          ...(this.paginatorData || []),
          ...data.value.data,
        ];
        this.consumptionLoading = false;
        this.isDone = true;
        return;
      }
      const newPaginatorData = [
        ...(this.paginatorData || []),
        ...data.value.data,
      ];
      this.paginatorData = toRaw(newPaginatorData).map((el) => toRaw(el));
      this.consumptionLoading = false;
    },
    async resetPaginatorConsumer() {
      this.isDone = false;
      this.paginatorData = [];
      this.pagesLoaded = 0;
      this.slidesLoaded = 0;
      await this.setupPaginatorConsumer();
    },
    async initialContextCheck() {
      await this.checkSlideContext();
    },
    async checkSlideContext() {
      const oldTitle = this.slideTitle;
      const oldId = this.slideId;
      await this.checkPresentationTitle();
      await this.checkSlideTitle();
      this.checkContextChanges(oldTitle, oldId);
    },
    async checkSlideTitle() {
      return new Promise((resolve, reject) => {
        window.Office.context.document.getSelectedDataAsync(
          window.Office.CoercionType.SlideRange,
          (asyncResult) => {
            if (asyncResult.status !== "failed") {
              const context = asyncResult.value.slides[0];
              this.slideTitle = context.title;
              this.slideIndex = context.index;
              resolve();
            } else {
              reject();
            }
          }
        );
      });
    },
    async checkPresentationTitle() {
      return new Promise((resolve) => {
        window.Office.context.document.getFilePropertiesAsync((asyncResult) => {
          const url = asyncResult.value.url;
          const urlTail =
            url.split("/").length > 0 ? url.split("/").slice(-1)[0] : url;
          const withoutExtension =
            urlTail.split(".").length > 0 ? urlTail.split(".")[0] : urlTail;
          const title = withoutExtension.split("_").join(" ");
          this.presentationTitle = title;
          resolve();
        });
      });
    },
    checkContextChanges(oldTitle, oldId) {
      if (this.slideId !== oldId) {
        this.searchTrigger = {
          trigger: searchTrigger.slide_change,
          detail: searchDetail.slide_change.switch_slide,
        };
        this.contextualSearch();
        return;
      }
      if (this.slideTitle !== oldTitle) {
        this.notTypingTicks = 0;
      } else {
        if (this.notTypingTicks !== this.notTypingTrigger) {
          this.notTypingTicks += 1;
        } else if (this.searchQuery !== this.slideTitle) {
          this.searchTrigger = {
            trigger: searchTrigger.slide_change,
            detail: searchDetail.slide_change.current_slide_change,
          };
          this.contextualSearch();
        }
      }
    },
    async refresh() {
      await this.checkSlideContext();
      this.searchTrigger = {
        trigger: searchTrigger.slide_change,
        detail: searchDetail.slide_change.refresh,
      };
      this.contextualSearch();
    },
    contextualSearch() {
      if (!this.searchingByQuery) {
        if (this.slideTitle) {
          if (this.searchQuery !== this.slideTitle) {
            this.setSearchQuery(this.slideTitle);
          }
        } else if (this.presentationTitle) {
          if (this.searchQuery !== this.presentationTitle)
            this.setSearchQuery(this.presentationTitle);
        }
      }
    },
    async handleResultsScroll() {
      const el = this.$refs.results;
      const isEnd = el.scrollTop >= el.scrollHeight - el.clientHeight - 3;
      if (isEnd) {
        await this.loadPage();
      }
      if (this.scrollTimer) clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(this.sendViewEvent, 1000);
    },
    initialViewEvent() {
      this.maxScrollY =
        this.visualisationElements[0].getBoundingClientRect().y +
        this.visualisationElements[0].getBoundingClientRect().height;
      const { newIndex, newElement } = this.getNewScrollIndex();
      sendBatchViewEvent({
        ids: this.paginatorData.slice(0, newIndex + 1).map((res, idx) => ({
          subresource_trace_id: res.trace_id,
          resource_trace_id: res.references[0].resource.trace_id,
          rank: idx + 1,
        })),
      });
      this.scrollIndex = newIndex;
      this.maxScrollY = newElement.y + newElement.height;
    },
    sendViewEvent() {
      if (
        this.visualisationElements[this.scrollIndex].getBoundingClientRect().y >
        this.maxScrollY
      )
        return;
      const { newIndex, newElement } = this.getNewScrollIndex();
      if (newIndex === this.scrollIndex) return;
      const sliceStart = this.scrollIndex + 1;
      sendBatchViewEvent({
        ids: this.paginatorData
          .slice(sliceStart, newIndex + 1)
          .map((res, idx) => ({
            subresource_trace_id: res.trace_id,
            resource_trace_id: res.references[0].resource.trace_id,
            rank: sliceStart + idx + 1,
          })),
      });
      this.scrollIndex = newIndex;
      this.maxScrollY = newElement.y + newElement.height;
    },
    getNewScrollIndex() {
      let newIndex = this.scrollIndex;
      let newElement =
        this.visualisationElements[newIndex].getBoundingClientRect();
      while (newElement.y + newElement.height < window.innerHeight) {
        if (newIndex + 1 === this.visualisationElements.length) break;
        newIndex += 1;
        newElement =
          this.visualisationElements[newIndex].getBoundingClientRect();
      }
      if (newIndex + 1 < this.visualisationElements.length) {
        newIndex -= 1;
        newElement =
          this.visualisationElements[newIndex].getBoundingClientRect();
      }
      return { newIndex, newElement };
    },
    toggleFilter(query) {
      const type = Object.keys(query)[0];
      const value = query[type];
      this.activeFilters[type] = (this.activeFilters[type] || []).includes(
        value
      )
        ? this.activeFilters[type].filter((v) => v !== value)
        : [...(this.activeFilters[type] || []), value];
      this.resetPaginatorConsumer();
    },
  },
  watch: {
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (
          newVal === "" &&
          this.pollingEnabled &&
          (this.slideTitle || this.presentationTitle)
        )
          this.refresh();
        else {
          this.searchTrigger = {
            trigger: searchTrigger.search,
            detail: searchDetail.search.input,
          };
          this.resetPaginatorConsumer();
        }
      }
    },
    workspace_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetPaginatorConsumer();
      }
    },
    async pollingEnabled(newVal) {
      if (newVal) {
        this.contextListener = setInterval(this.checkSlideContext, 100);
      } else {
        clearInterval(this.contextListener);
        this.contextListener = undefined;
      }
    },
    consumptionLoading(newVal) {
      if (!newVal) {
        this.$nextTick(
          () =>
            (this.visualisationElements = Array.from(
              document.getElementsByClassName("data-visualisation-scroll")
            ))
        );
      }
    },
    allSlidesLoaded(newVal) {
      if (newVal) {
        if (this.initialSlideLoad) {
          this.initialViewEvent();
          this.initialSlideLoad = false;
          return;
        }
        this.sendViewEvent();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  display: grid;
  grid-template-rows: 6rem auto;

  height: 100%;
  width: 100%;

  box-sizing: border-box;

  position: relative;

  &-results {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.4rem;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    grid-row: 2;
    align-items: center;
    border-top: solid 1px lightgrey;

    .result {
      position: relative;

      &-info {
        position: absolute;
        top: 1rem;
        left: 2rem;
        z-index: 150;
      }
    }
  }
}
</style>
