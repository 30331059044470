import Console from "@/console";
import { getClient } from "../axios";
import { getResponseData } from "../axios";

export default {
  state: {
    workspaces: [],
    workspaceIds: [],
    currentWorkspace: undefined,
    currentWorkspaceId: undefined,
    workspacesEmpty: false,
  },
  getters: {
    currentWorkspace: (state) => state.currentWorkspace,
    showCollectSlides: (state) =>
      state.currentWorkspace?.settings.find((s) => s.name === "collect_slides")
        ?.enabled || false,
    showPollingToggle: (state) =>
      state.currentWorkspace?.settings.find(
        (s) => s.name === "powerpoint_addin_context_polling"
      )?.enabled || false,
  },
  mutations: {
    upsertWorkspaces(state, workspaces) {
      for (const workspace of workspaces) {
        state.workspaces[workspace.uuid] = {
          ...state.workspaces[workspace.uuid],
          workspace,
        };
      }
      state.workspaceIds = workspaces.map((x) => x.uuid);
    },
    setCurrentWorkspace(state, uuid) {
      if (state.workspaces[uuid]) {
        const newWorkspace = state.workspaces[uuid].workspace;
        localStorage.setItem("lastWorkspace", uuid);
        state.currentWorkspace = newWorkspace;
        state.currentWorkspaceId = uuid;
      }
    },
    setWorkspacesEmpty(state, empty) {
      state.workspacesEmpty = empty;
    },
  },
  actions: {
    async getWorkspaces({ commit, dispatch }) {
      try {
        const client = getClient("/workspaces");
        const res = await client.get("/");
        const data = getResponseData(res);
        Console.log("Received workspaces: ", data);
        commit("upsertWorkspaces", data);
        commit("setWorkspacesEmpty", data.length === 0);

        if (data.length === 0) {
          console.error("No workspaces found for account");
          dispatch("logout");
          dispatch("setToast", {
            title: "You are not in a workspace",
            subtitle:
              "You don't seem to be a member of any workspace. If that's incorrect, contact us or your workspace administrator",
            type: "info",
          });
        } else {
          const lastUUID = localStorage.getItem("lastWorkspace");
          const badVals = [undefined, null, "undefined", "null"];
          if (
            badVals.every((val) => lastUUID !== val) &&
            data.findIndex((el) => el.uuid === lastUUID) !== -1
          ) {
            commit("setCurrentWorkspace", lastUUID);
          } else {
            commit("setCurrentWorkspace", data[0].uuid);
          }
        }

        return data;
      } catch (e) {
        console.error("Error during workspace data fetch", e);
        dispatch("logout");
        dispatch("setToast", {
          title: "Something went wrong",
          subtitle: "We couldn't get the info about your workspaces.",
          type: "error",
        });
      }
    },
  },
  modules: {},
};
