export default {
  state: {
    showToast: false,
    toastData: undefined,
  },
  getters: {},
  mutations: {
    setShowToast(state, show) {
      state.showToast = show;
    },
    setToastData(state, data) {
      state.toastData = data;
    },
  },
  actions: {
    setToast({ commit }, toastData) {
      commit("setShowToast", toastData !== undefined);
      commit("setToastData", toastData);
    },
  },
  modules: {},
};
