<template>
  <div class="dropdown">
    <div class="dropdown-trigger" @click="opened = !opened">
      <div
        class="dropdown-icon"
        :style="{ backgroundColor: backgroundColor(currentWorkspace) }"
      >
        <img
          v-if="currentWorkspace.image"
          :src="currentWorkspace.image"
          alt=""
          class="dropdown-icon-img"
        />
        <span v-else class="dropdown-icon-letter">
          {{ workspaceLetter(currentWorkspace) }}
        </span>
      </div>
      <div class="dropdown-trigger-name-container">
        <span class="dropdown-trigger-name">{{ currentWorkspace.name }}</span>
      </div>
      <img
        v-if="hasWorkspaceOptions"
        src="@a/icons/dropdown-chevron.svg"
        alt=""
        :class="{
          'dropdown-trigger-chevron': true,
          'dropdown-trigger-chevron-opened': opened,
        }"
      />
    </div>
    <div v-if="opened" class="dropdown-options">
      <p class="dropdown-options-title">Choose a workspace</p>
      <div class="dropdown-options-container">
        <div
          v-for="workspace in workspaceOptions"
          class="option"
          :key="workspace.uuid"
          @click="handleWorkspaceClicked(workspace.uuid)"
        >
          <div class="option-info">
            <div
              class="dropdown-icon"
              :style="{ backgroundColor: backgroundColor(workspace) }"
            >
              <img
                v-if="workspace.image"
                :src="workspace.image"
                alt=""
                class="dropdown-icon-img"
              />
              <span v-else class="dropdown-icon-letter">
                {{ workspaceLetter(workspace) }}
              </span>
            </div>
            <span class="option-info-name">{{ workspace.name }}</span>
          </div>
          <img
            v-if="workspace.uuid === currentWorkspace.uuid"
            src="@a/icons/check.svg"
            alt=""
            class="option-check"
          />
        </div>
      </div>
    </div>
    <div
      v-if="opened"
      class="dropdown-background"
      @click="opened = false"
    ></div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { userColor } from "../util";

export default {
  name: "WorkspaceDropdown",
  data: () => ({
    opened: false,
  }),
  computed: {
    ...mapState({
      currentWorkspace: (state) => state.workspaces.currentWorkspace,
      workspaces: (state) => state.workspaces.workspaces,
    }),
    hasWorkspaceOptions() {
      return Object.keys(this.workspaces).length > 1;
    },
    workspaceOptions() {
      return Object.keys(this.workspaces).map(
        (key) => this.workspaces[key].workspace
      );
    },
  },
  methods: {
    ...mapMutations(["setCurrentWorkspace"]),
    workspaceLetter(workspace) {
      return workspace.name[0].toUpperCase();
    },
    backgroundColor(workspace) {
      return !workspace.image ? userColor(workspace) : "white";
    },
    handleWorkspaceClicked(uuid) {
      this.setCurrentWorkspace(uuid);
      this.opened = false;
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: default;

  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    z-index: 200;

    &-name {
      max-width: 15ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-container {
        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-chevron {
      height: 0.75rem;
      transition: all 500ms ease;

      &-opened {
        transform: rotate(180deg);
      }
    }
  }

  &-icon {
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    border-radius: 10px;
    overflow: hidden;
    &-img {
      height: 1.5rem;
    }

    &-letter {
      color: white;
    }
  }

  &-options {
    position: absolute;
    top: 3rem;
    left: -2rem;
    width: calc(100% + 4rem);
    border-radius: 5px;
    border: 1px solid #0000000f;
    background: white;
    z-index: 200;
    overflow: hidden;
    &-title {
      width: 100%;
      padding: 1rem 0;
      color: grey;
      text-align: center;
    }

    &-container {
      .option {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0.25rem 0.5rem;
        display: flex;
        flex-flow: row nowrap;

        &:hover {
          background: #f1f2f3;
        }

        &-info {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }

  &-background {
    z-index: 195;
    background: #ffffffaa;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
