<template>
  <div class="info" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <img v-if="hasInfo" src="@a/icons/info.svg" alt="" class="icon" />
    <div v-if="hovered" class="info-block">
      <div v-if="slide.references.length > 0" class="info-block-references">
        <p class="title">{{ referenceCountText }}</p>
        <div
          v-for="reference in slide.references"
          class="reference"
          :key="reference.resource.uuid"
          @click="handleReferenceClicked(reference)"
        >
          <img
            v-if="isPPTX(reference)"
            src="@a/icons/mimetypes/pptx.svg"
            alt=""
            class="reference-icon"
          />
          <img
            v-else
            src="@a/icons/mimetypes/googleslides.svg"
            alt=""
            class="reference-icon"
          />
          <span class="reference-title">{{ reference.resource.title }}</span>
        </div>
      </div>
      <div v-if="tags.length > 0" class="info-block-tags">
        <p class="title">Slide tags</p>
        <div class="info-block-tags-container">
          <div v-for="tag in tags" class="tag" :key="tag">
            {{ tag }}
          </div>
        </div>
      </div>
      <div v-if="hasLabels" class="info-block-labels">
        <p class="title">Quality labels</p>
        <div class="info-block-labels-container">
          <div
            v-for="label in slide.labels"
            :key="`${slide.uuid}-label-${label.uuid}`"
            class="label"
          >
            <img
              :src="require(`@a/icons/qualitylabels/${label.type}.svg`)"
              alt=""
              class="label-img"
            />
            <span class="label-name">{{ label.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sendClickThroughEvent } from "@/store/services/feedbackService";

export default {
  name: "InfoButton",
  props: {
    slide: {
      type: Object,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    hovered: false,
  }),
  computed: {
    ...mapState({
      lastSearchId: (state) => state.resources.lastSearchId,
      currentWorkspace: (state) => state.workspaces.currentWorkspace,
    }),
    referenceCountText() {
      return `Appears in ${this.slide.references.length} document${
        this.slide.references.length > 1 ? "s" : ""
      }`;
    },
    hasLabels() {
      return this.slide.labels.length > 0;
    },
    tags() {
      return [
        ...(this.slide.topics?.tags || []),
        ...(this.slide.organisations || []),
        ...(this.slide.industries || []),
      ].map((el) => el.name);
    },
    hasInfo() {
      return (
        this.slide.references.length > 0 ||
        this.hasLabel ||
        this.tags.length > 0
      );
    },
  },
  methods: {
    isPPTX(reference) {
      const mimetype = reference.resource.integrationfile.mimetype;
      return mimetype !== "application/vnd.google-apps.presentation";
    },
    async handleReferenceClicked(reference) {
      sendClickThroughEvent({
        subresource_trace_id: this.slide.trace_id,
        resource_trace_id: reference.resource.trace_id,
        rank: this.rank,
      });
      window.open(reference.url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  &-block {
    position: absolute;
    top: 1.5rem;
    left: -1.5rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 0.75rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: 1rem;

    background: white;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);

    width: fit-content;
    min-width: 80vw;

    &-references {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      .reference {
        display: grid;
        grid-template-columns: 1.4rem auto;
        align-items: center;
        &-icon {
          height: 1.2rem;
          grid-column: 1;
        }

        &-title {
          width: 100%;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          grid-column: 2;
          color: #115efb;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    &-tags {
      &-container {
        display: flex;
        flex-flow: row wrap;
        gap: 0.2rem;
      }
      .tag {
        padding: 0.5rem 0.7rem;
        border-radius: 999rem;
        border: 1px solid #dddfe2;
        color: #60666b;
      }
    }

    &-labels {
      &-container {
        display: flex;
        flex-flow: row wrap;
      }
      .label {
        display: flex;
        flex-flow: row nowrap;
        gap: 0.2rem;
        padding: 0.5rem 0.7rem;
        border-radius: 999rem;
        border: 1px solid #dddfe2;
        color: #60666b;
        &-img {
          height: 1.2rem;
        }

        &-name {
          color: #60666b;
        }
      }
    }

    .title {
      font-weight: 600;
      margin-bottom: 0.6rem;
    }
  }
  .icon {
    height: 1.5rem;
  }
}
</style>
