<template>
  <div class="empty">
    <div class="empty-block">
      <img src="@a/icons/search.svg" alt="" class="empty-block-img" />
      <p class="empty-block-header">
        Sorry, we couldn't find anything for "{{ searchQuery }}"
      </p>
      <p class="empty-block-sub">
        Please try rephrasing your search with keywords.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchEmpty",
  computed: {
    ...mapState({
      searchQuery: (state) => state.resources.searchQuery,
    }),
  },
};
</script>

<style scoped lang="scss">
.empty {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2rem 1rem;
  &-block {
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 0.5rem;
    border: 3px solid #f7f7fc;
    border-radius: 9px;
    &-img {
      filter: invert(69%) sepia(4%) saturate(1419%) hue-rotate(186deg)
        brightness(88%) contrast(88%);
      height: 1.75rem;
    }

    &-header {
      font-weight: 700;
    }

    &-sub {
      color: #4f4f4f;
    }
  }
}
</style>
