<template>
  <div :class="{ toast: true, active: showToast }">
    <div
      v-if="showToast"
      class="toast-content"
      :style="`--border-color: ${borderColor}`"
    >
      <img
        :src="require(`@a/icons/toast/${toastData.type}.svg`)"
        alt=""
        class="toast-content-img"
      />
      <div class="toast-content-text">
        <div class="toast-content-text-title">{{ toastData.title || "" }}</div>
        <div class="toast-content-text-subtitle">
          {{ toastData.subtitle || "" }}
        </div>
      </div>
      <img
        src="@a/icons/close.svg"
        alt=""
        class="toast-content-close"
        @click="closeToast"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "InfoToast",
  computed: {
    ...mapState({
      showToast: (state) => state.addin.showToast,
      toastData: (state) => state.addin.toastData,
    }),
    borderColor() {
      const colorMap = {
        success: "#24CE85",
        error: "#FC0D1B",
        info: "#115EFB",
        default: "#24CE85",
      };
      return this.toastData
        ? colorMap[this.toastData.type || "default"]
        : colorMap["default"];
    },
  },
  methods: {
    ...mapActions(["setToast"]),
    closeToast() {
      this.setToast(undefined);
    },
  },
  watch: {
    showToast(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.closeToast();
        }, 5000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.toast {
  position: fixed;
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(#000, 8%);
  bottom: 0.5rem;
  left: 0.5rem;
  width: calc(100vw - 1rem);
  transition: all ease 200ms;
  max-height: 15rem;
  transform: translateY(40rem);
  z-index: 999;
  &.active {
    transform: translateY(0);
  }

  &-content {
    border-left: 4px solid var(--border-color);
    display: grid;
    grid-template-columns: 3rem auto 3rem;
    justify-items: center;
    align-items: start;
    padding: 1rem 0;

    &-img {
      height: 1.2rem;
      user-select: none;
    }

    &-text {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #303032;
      }

      &-subtitle {
        font-size: 14px;
        color: #8f9399;
      }
    }

    &-close {
      height: 1.2rem;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
