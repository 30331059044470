<template>
  <div class="pills-wrapper">
    <div class="pills scrollable" ref="pillcontainer" @scroll="checkArrows">
      <div
        v-for="pill in pillsOptions"
        :key="pill.label"
        class="pills-pill"
        :class="{ active: pill.active }"
        @click="toggleFilter(pill)"
      >
        <img
          :src="require(`@/assets/icons/${pill.icon}.svg`)"
          alt=""
          class="pills-pill-icon"
        />
        {{ pill.label }}
      </div>
    </div>
    <div
      v-if="showScrollArrow.left"
      class="pills-arrow left"
      @click="() => arrowClicked(-1)"
    >
      <img
        src="@/assets/icons/arrow-right.svg"
        alt=""
        class="pills-arrow-icon"
      />
    </div>
    <div
      v-if="showScrollArrow.right"
      class="pills-arrow right"
      @click="() => arrowClicked(1)"
    >
      <img
        src="@/assets/icons/arrow-right.svg"
        alt=""
        class="pills-arrow-icon"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "PillFilters",
  props: {
    activeFilters: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    overflowing: false,
    showScrollArrow: {
      left: false,
      right: false,
    },
    resizeListener: null,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    ...mapGetters(["labels", "bookmarks"]),
    pillsOptions() {
      const options = [
        {
          label: "Bookmarks",
          icon: "bookmark",
          active: (this.activeFilters.bookmarks?.length || 0) > 0,
          query: {
            bookmarks: this.currentUser.uuid,
          },
        },
        ...this.labels
          .filter((label) => ["positive", "informative"].includes(label.type))
          .map((label) => ({
            label: label.name,
            icon: "qualitylabels/positive",
            active: (this.activeFilters.labels || []).includes(label.name),
            query: {
              labels: label.name,
            },
          })),
        ...(this.activeFilters.labels || [])
          .filter((label) => this.labels.findIndex((l) => l.name === label) < 0)
          .map((label) => ({
            label,
            icon: "qualitylabels/positive",
            active: true,
            query: {
              labels: label,
            },
          })),
      ];
      return [
        ...options.filter((o) => o.active),
        ...options.filter((o) => !o.active),
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.checkOverflow();
      this.checkArrows();
    }, 500);
    this.resizeListener = () => {
      this.checkOverflow();
      this.checkArrows();
    };
    window.addEventListener("resize", this.resizeListener);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    toggleFilter(pill) {
      this.$emit("toggleFilter", pill.query);
    },
    arrowClicked(delta) {
      this.$refs.pillcontainer.scrollBy({
        left: delta * 100,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.checkArrows();
      }, 500);
    },
    checkOverflow() {
      const el = this.$refs.pillcontainer;
      if (!el) return;
      this.overflowing = el.scrollWidth > el.clientWidth;
    },
    checkArrows() {
      const el = this.$refs.pillcontainer;
      this.showScrollArrow.left = this.overflowing && el.scrollLeft > 5;
      this.showScrollArrow.right =
        this.overflowing && el.scrollWidth - el.scrollLeft - 5 > el.clientWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.pills {
  &-wrapper {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    position: relative;
  }

  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
  gap: 0.5rem;
  box-sizing: border-box;
  overflow-x: auto;

  &-pill {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border-radius: 6px;
    background: #f1f2f3;
    cursor: pointer;

    color: #8f9399;
    font-weight: 600;
    white-space: nowrap;

    &-icon {
      height: 1rem;
      filter: grayscale(1);
    }

    &:hover {
      background: #e6e7e8;
    }

    &.active {
      background: #303032;
      color: white;

      & > .pills-pill-icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 999rem;
    border: 1px solid #dddfe2;
    background: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;

    &-icon {
      height: 0.75rem;
    }

    &:hover {
      background: #dddfe2;
    }

    &.left {
      left: 1.2rem;
      transform: rotate(180deg) translateY(50%);
    }

    &:not(.left) {
      right: 1.2rem;
    }
  }
}

.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable {
  -ms-overflow-style: auto;
  scrollbar-width: none;
}
</style>
