import { getClient, getResponseData } from "../axios";
import jwt_decode from "jwt-decode";
import Console from "@/console";

let timeout = 1;

export default {
  state: {
    accessToken: undefined,
    refreshToken: undefined,
    refreshCycle: undefined,
    currentUser: undefined,
  },
  getters: {
    tokensInStorage(state) {
      return (
        state.accessToken !== undefined &&
        state.refreshToken !== undefined &&
        (jwt_decode(state.accessToken).exp || 0) * 1000 > Date.now() &&
        (jwt_decode(state.refreshToken).exp || 0) * 1000 > Date.now()
      );
    },
    authenticated(_, getters, rootState) {
      return getters.tokensInStorage && rootState.workspaces.currentWorkspaceId;
    },
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    setAccessToken(state, token) {
      localStorage.setItem("accessToken", token);
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      localStorage.setItem("refreshToken", token);
      state.refreshToken = token;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    login({ commit, dispatch }, tokens) {
      try {
        commit("setAccessToken", tokens.access);
        commit("setRefreshToken", tokens.refresh);
        dispatch("startRefreshCycle");
        dispatch("getCurrentUser");
        dispatch("getWorkspaces");
      } catch (e) {
        console.error("Error during login", e);
        dispatch("logout");
        dispatch("setToast", {
          title: "Login error",
          subtitle:
            "We tried logging you back in, but something went wrong. Try logging in again.",
          type: "error",
        });
      }
    },
    logout({ commit, dispatch }) {
      try {
        commit("setAccessToken", undefined);
        commit("setRefreshToken", undefined);
        commit("setWorkspacesEmpty", false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      } catch (e) {
        console.error("Error during logout", e);
        dispatch("setToast", {
          title: "Logout error",
          subtitle: "Something went wrong while logging out",
          type: "error",
        });
      }
    },

    async refresh({ state, commit, dispatch }) {
      if (state.refreshToken === null) return undefined;

      const sleep = (duration) =>
        new Promise((resolve) => setTimeout(resolve, duration));

      await sleep(timeout);
      const client = getClient("/users");
      let res = {};
      try {
        res = await client.post("/token/refresh/", {
          refresh: state.refreshToken,
        });
      } catch (e) {
        timeout = Math.max(timeout * 10, 6e4);
        return await dispatch("refresh");
      }

      commit("setAccessToken", res.data.access);
      if ("refresh" in res.data) {
        commit("setRefreshToken", res.data.refresh);
        timeout = 1;
      } else {
        timeout = Math.max(timeout * 10, 6e4);
        return await dispatch("refresh");
      }
      return getResponseData(res);
    },
    async startRefreshCycle({ state, dispatch }) {
      if (state.refreshCycle) clearTimeout(state.refreshCycle);

      const REFRESH_MARGIN = 6e4;
      /** getTimeout returns the time to next refresh in ms */
      const getTimeout = () => {
        const expiry = jwt_decode(state.accessToken).exp || 0;
        return expiry * 1000 - Date.now() - REFRESH_MARGIN;
      };

      /** newTimeout schedules a new timeout and automatically schedules the following ones */
      const newTimeout = (timeout) => {
        if (state.refreshCycle) {
          clearTimeout(state.refreshCycle);
        }

        state.refreshCycle = setTimeout(async function () {
          try {
            await dispatch("refresh");
            newTimeout(Math.max(getTimeout(), 10000)); // minimum 10 seconds between refresh calls
          } catch (err) {
            if (state.refreshToken != null) {
              // Double the timeout each time with a max timeout of 5 minutes
              newTimeout(Math.min(timeout * 2, 5 * 6e4));
            }
          }
        }, timeout);
      };

      // get the initial time until refresh, minimum 0 ms
      const timeout = Math.max(getTimeout(), 0);

      newTimeout(timeout);
    },

    async getCurrentUser({ commit, dispatch }) {
      try {
        const client = getClient("/users");
        const res = await client.get("/me/");
        const user = getResponseData(res);
        Console.log("Received user:", user);
        commit("setCurrentUser", user);
        return user;
      } catch (e) {
        if (e.response) {
          // The client was given an error response (5xx, 4xx)
          dispatch("logout");
          const toastMap = {
            400: "Our server didn't recognize this request",
            401: "It seems no authentication was provided.",
            403: "You tried to access something that's forbidden.",
            404: "We didn't find your user info",
            408: "The request seems to haven taken too long",
            500: "Something's wrong on our side, try again later",
            default: "Something went wrong, contact us for more information.",
          };
          dispatch("setToast", {
            title: "Login error",
            subtitle:
              toastMap[
                Object.keys(toastMap).includes(e.response.status)
                  ? e.response.status
                  : "default"
              ],
            type: "error",
          });
        } else if (e.request) {
          // The client never received a response, and the request was never left
          dispatch("logout");
          dispatch("setToast", {
            title: "Login error",
            subtitle: "Didn't get a response, try again later",
            type: "error",
          });
        } else {
          // Anything else
          console.log("Error", e.message);
          throw e;
        }
      }
    },
  },
  modules: {},
};
