import axios from "axios";
import store from "./index.js";

export function getClient(baseUrl, ApiUrl = undefined) {
  const backendHost = localStorage.getItem("backendHost");
  const options = {
    baseURL: (ApiUrl || backendHost) + baseUrl,
    headers: {
      Accept: "application/json; version=1;",
      "Cache-Control": "no-cache",
    },
    validateStatus: (status) => {
      return (status >= 200 && status < 300) || status === 304;
    },
  };

  let client = axios.create(options);

  client.interceptors.request.use(
    (config) => {
      config.headers["uman-Client"] = "powerpoint-addin";
      const accessToken = store.state.authentication.accessToken;
      const invalidTokens = [null, "null", undefined, "undefined"];
      if (
        !invalidTokens.includes(accessToken) &&
        config.url !== "/token/refresh/"
      )
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  return client;
}

export function getResponseData(response) {
  if (response.data instanceof Object && "results" in response.data) {
    if (response.data.results.search_id)
      store.commit("setLastSearchId", response.data.results.search_id);
    return response.data.results;
  }

  return response.data;
}
