<template>
  <div class="slide">
    <div class="slide-img-container" @click="openSlideDetail()">
      <div class="slide-hover-scrim"></div>
      <div class="slide-btns">
        <AddButton
          v-if="showCollectSlides"
          :slide="slide"
          :rank="rank"
          class="slide-btns-add"
          @inserting="insertingSlide = true"
          @insertProgress="(n) => (insertProgress = n)"
        />
      </div>
      <QualityLabels :slide="slide" class="slide-quality-labels" />
      <img
        ref="slide-img"
        :src="slide.preview"
        alt=""
        class="slide-img"
        @load="$emit('load')"
        @error="$emit('error')"
      />
      <div v-if="insertProgress > 0" class="slide-insert-progress-bar">
        <div class="progress" :style="progressBarStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendInspectEvent } from "@/store/services/feedbackService";
import { mapActions, mapGetters } from "vuex";
import AddButton from "./AddButton.vue";
import QualityLabels from "./QualityLabels.vue";

export default {
  name: "SlideVisualisation",
  components: { AddButton, QualityLabels },
  props: {
    slide: {
      type: Object,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    insertProgress: 0,
    randomInsertProgressInterval: undefined,
  }),
  computed: {
    ...mapGetters(["showCollectSlides"]),
    progressBarStyle() {
      return { width: `${this.insertProgress}%` };
    },
  },
  methods: {
    ...mapActions(["setToast"]),
    async openSlideDetail() {
      const height_px = screen.height * 0.8;
      const img_el = this.$refs["slide-img"].getBoundingClientRect();
      const width = Math.round(
        (((img_el.width / img_el.height) * height_px) / screen.width) * 100
      );
      sendInspectEvent({
        subresource_trace_id: this.slide.trace_id,
        resource_trace_id: this.slide.references[0].resource.trace_id,
        rank: this.rank,
      });
      window.Office.context.ui.displayDialogAsync(
        `${process.env.VUE_APP_WEBAPP_HOST}/modal.html?img=${encodeURIComponent(
          this.slide.preview
        )}`,
        { height: 80, width },
        (res) => {
          res.value.addEventHandler(
            window.Office.EventType.DialogMessageReceived,
            (arg) => {
              if (arg.message === "close") {
                res.value.close();
              }
            }
          );
        }
      );
    },
  },
  watch: {
    insertProgress(newValue) {
      if (newValue === 20) {
        this.randomInsertProgressInterval = setInterval(() => {
          const newProgress =
            this.insertProgress + 12 + Math.round(Math.random() * 8);
          this.insertProgress = newProgress >= 90 ? 90 : newProgress;
        }, 1000);
      } else if (newValue === 100) {
        clearInterval(this.randomInsertProgressInterval);
        this.randomInsertProgressInterval = undefined;
        setTimeout(() => {
          this.insertProgress = 0;
        }, 300);
        this.setToast({
          title: "Slide inserted!",
          subtitle: "Your slide has been successfully inserted",
          type: "success",
        });
      } else if (newValue === -1) {
        clearInterval(this.randomInsertProgressInterval);
        this.randomInsertProgressInterval = undefined;
        this.insertProgress = 0;
        this.setToast({
          title: "Failed to insert slide",
          subtitle: "Something went wrong during slide insertion",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.slide {
  transform: scale(1);
  width: fit-content;
  z-index: 120;
  padding: 0 1rem;
  &-img {
    width: 100%;
    display: block;
    &-container {
      border-radius: 3px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.08);
      cursor: zoom-in;
      position: relative;

      &:hover {
        .slide-hover-scrim {
          opacity: 15%;
        }

        .slide-btns {
          opacity: 100%;
        }
      }
    }
  }

  &-btns {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-add {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }

    &-icon {
      height: 1.5rem;
    }
  }
  &-quality-labels {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &-hover-scrim {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.0127407) 6.47%,
      rgba(0, 0, 0, 0.0485926) 12.46%,
      rgba(0, 0, 0, 0.104) 18.04%,
      rgba(0, 0, 0, 0.175407) 23.29%,
      rgba(0, 0, 0, 0.259259) 28.27%,
      rgba(0, 0, 0, 0.352) 33.08%,
      rgba(0, 0, 0, 0.450074) 37.77%,
      rgba(0, 0, 0, 0.549926) 42.42%,
      rgba(0, 0, 0, 0.648) 47.11%,
      rgba(0, 0, 0, 0.740741) 51.91%,
      rgba(0, 0, 0, 0.824593) 56.9%,
      rgba(0, 0, 0, 0.896) 62.15%,
      rgba(0, 0, 0, 0.951407) 67.73%,
      rgba(0, 0, 0, 0.987259) 73.72%,
      #000000 80.19%
    );
    transform: rotate(180deg);
    opacity: 0;
    transition: opacity 0.4s ease;
    height: 100%;
    width: 100%;
  }

  &-insert-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    width: 100%;
    .progress {
      height: 10px;
      background: #115efb;
      transition: all 200ms ease;
    }
  }
}
</style>
