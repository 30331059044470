import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const initSentry = () => {
  Sentry.init({
    App,
    dsn: "https://4492be71f4bd4b64943a7d25c9013c70@o1108422.ingest.sentry.io/4504130460188672",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};

window.Office.initialize = () => {
  window.Office.addin.setStartupBehavior(window.Office.StartupBehavior.load);
  initSentry();
  createApp(App).use(store).mount("#app");
};
