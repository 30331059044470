<template>
  <div class="header" :class="{ 'polling-hidden': !showPollingToggle }">
    <div class="header-top">
      <p class="header-top-text">{{ header }}</p>
      <p
        v-if="!pollingEnabled"
        class="header-top-refresh"
        @click="$emit('refresh')"
      >
        <img
          src="@/assets/icons/refresh.svg"
          alt=""
          class="header-top-refresh-icon"
        />
        Refresh
      </p>
    </div>
    <div class="header-polling" v-if="showPollingToggle">
      <p class="header-polling-text">
        Turn {{ pollingEnabled ? "off" : "on" }} auto suggestions
      </p>
      <div class="header-polling-toggle">
        <SwitchToggle
          :toggled="pollingEnabled"
          @update:toggled="handlePollingToggle"
        />
        <p v-if="OSIsWindows" class="header-polling-toggle-popup">
          Warning: in PowerPoint for Windows, toggling this setting on can cause
          the cursor to be stuck in a loading state.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Console from "@/console";
import { mapGetters } from "vuex";
import SwitchToggle from "../atoms/SwitchToggle.vue";

export default {
  name: "SearchResultHeader",
  components: { SwitchToggle },
  props: {
    pollingEnabled: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["showPollingToggle"]),
    OSIsWindows() {
      const windowsRegexes = [
        /(Windows 10.0|Windows NT 10.0)/,
        /(Windows 8.1|Windows NT 6.3)/,
        /(Windows 8|Windows NT 6.2)/,
        /(Windows 7|Windows NT 6.1)/,
        /Windows NT 6.0/,
      ];
      return (
        !navigator.userAgent ||
        windowsRegexes.some((regex) => regex.test(navigator.userAgent))
      );
    },
  },
  methods: {
    handlePollingToggle(toggled) {
      this.$emit("pollingChange", toggled);
      window.Office.context.document.settings.set("pollingEnabled", toggled);
      window.Office.context.document.settings.saveAsync((asyncResult) => {
        Console.log("Updated user settings");
        if (asyncResult.status !== window.Office.AsyncResultStatus.Succeeded) {
          this.setToast({
            title: "Setting couldn't be saved",
            subtitle:
              "Something went wrong when saving this settings, please try again or contact our support.",
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;

  &.polling-hidden {
    padding: 0 1rem;
    margin: 1rem 0;
  }

  &:not(.polling-hidden) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    min-height: 6rem;
    gap: 0.2rem;
    margin: 0.75rem 0 0.5rem;
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-text {
      color: #303032;
      font-weight: 600;
    }

    &-refresh {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #303032;
      font-weight: 600;
      padding: 0.4rem 0.8rem;
      border-radius: 999rem;
      border: 1px solid rgba(#000, 8%);
      cursor: pointer;

      &:hover {
        background: rgba(#000, 8%);
      }

      &-icon {
        height: 1rem;
      }
    }
  }

  &-polling {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.75rem;
    background: rgba(#000, 8%);
    border-radius: 4px;

    &-text {
      color: #303032;
      font-weight: 300;
    }

    &-toggle {
      position: relative;

      &:hover > .header-polling-toggle-popup {
        opacity: 100%;
        pointer-events: all;
      }

      &-popup {
        opacity: 0;
        pointer-events: none;
        transition: opacity ease 300ms;
        position: absolute;
        z-index: 999;
        width: 80vw;
        background: #323232;
        color: white;
        top: 120%;
        right: 0;
        border-radius: 4px;
        padding: 0.5rem;
      }
    }
  }
}
</style>
