const debug = process.env.NODE_ENV != "production";

const Console = {
  /**
   * @param {any[]} msg
   */
  debug(...msg) {
    if (debug) {
      console.debug("[uman.ai add-in][DEBUG]", ...msg); // eslint-disable-line no-console
    }
  },
  log(...msg) {
    if (debug) {
      console.log("[uman.ai add-in]", ...msg); // eslint-disable-line no-console
    }
  },
  info(...msg) {
    console.info("[uman.ai add-in]", ...msg); // eslint-disable-line no-console
  },
  warning(...msg) {
    console.warn("[uman.ai add-in]", ...msg); // eslint-disable-line no-console
  },
  error(...msg) {
    console.error("[uman.ai add-in]", ...msg); // eslint-disable-line no-console
  },
};

export default Console;
