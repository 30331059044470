<template>
  <div class="login">
    <div class="login-active">
      <img src="@a/logo-loggedout.svg" alt="" class="login-active-logo" />
      <div class="login-active-header">
        <p class="login-active-header-title">uman for PowerPoint</p>
        <p class="login-active-header-subtitle">
          Find and reuse slides made throughout the whole company.
        </p>
      </div>
      <div
        v-if="browserSupported && companyInputRequired"
        :class="{
          'login-active-company': true,
          active: companyInputRequired,
        }"
      >
        <p class="login-active-company-header">Enter your company's name</p>
        <input
          v-model="companyInput"
          type="text"
          class="login-active-company-input"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          placeholder="Company name"
        />
      </div>
      <div
        v-if="browserSupported"
        :class="{
          'login-active-btn': true,
          disabled: disableLoginButton,
        }"
        @click="!disableLoginButton && onLoginClick()"
      >
        Sign in
      </div>
      <div v-else class="login-active-unsupported">
        <img
          src="@a/icons/unsupported.svg"
          alt=""
          class="login-active-unsupported-img"
        />
        <span>
          Sorry, our add-in is not supported on your version of PowerPoint.
        </span>
        <span>
          To use uman's features in PowerPoint, Please upgrade either to
          perpetual Office 2021 or to a Microsoft 365 account.
        </span>
      </div>
    </div>
    <div class="login-info">
      <p class="login-info-grey">
        If you don't have a uman account yet, contact your workspace
        administrator to receive your invitation.
      </p>
      <p class="login-info-grey">or find out more about us here</p>
      <div class="login-info-contact">
        <a href="https://uman.ai" class="login-info-contact-link">
          <img src="@a/icons/web.svg" alt="" class="icon" />
          <span>uman.ai</span>
        </a>
        <a href="mailto:hello@uman.ai" class="login-info-contact-link">
          <img src="@a/icons/email.svg" alt="" class="icon" />
          <span>hello@uman.ai</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Console from "@/console";
import jwt_decode from "jwt-decode";
import { openAuthDialog } from "../util";

export default {
  name: "UmanLogin",
  data: () => ({
    altHosts: {},
    defaultHosts: {
      production: "https://api.uman.ai",
      development: "https://api.development.uman.ai",
    },
    companyInputRequired: false,
    companyInput: "",
  }),
  async created() {
    await this.getBackendHost();
  },
  methods: {
    onLoginClick() {
      if (this.companyInputRequired) {
        this.checkCompanyBackendHost();
      }
      openAuthDialog();
    },
    checkCompanyBackendHost() {
      const domainIdx = Object.keys(this.altHosts).findIndex((key) =>
        this.companyInput.toLowerCase().includes(key.toLowerCase())
      );
      if (domainIdx === -1) {
        this.setBackendHost(this.defaultHosts.production);
        return;
      }
      this.setBackendHost(this.altHosts[Object.keys(this.altHosts)[domainIdx]]);
    },
    async getBackendHost() {
      if (process.env.NODE_ENV === "development") {
        this.setBackendHost(this.defaultHosts.development);
        return;
      }
      if (window.Office.auth.getAccessToken === undefined) {
        // Method to get user logged into PPT doesn't exist => office version too old/incompatible
        this.companyInputRequired = true;
        return;
      }
      let PPTUser = "";
      try {
        const MSGraphAccessToken = await window.Office.auth.getAccessToken({
          allowConsentPrompt: true,
        });
        PPTUser = jwt_decode(MSGraphAccessToken)?.preferred_username;
      } catch (e) {
        Console.log("Error while trying to find backend host", e);
        this.companyInputRequired = true;
        return;
      }
      const domain =
        (PPTUser || "").split("@").length === 2
          ? PPTUser.split("@")[1].toLowerCase()
          : false;
      if (!domain) {
        this.companyInputRequired = true;
        return;
      }
      const domainIdx = Object.keys(this.altHosts).findIndex((key) =>
        domain.includes(key.toLowerCase())
      );
      if (domainIdx === -1) {
        this.setBackendHost(this.defaultHosts.production);
        return;
      }
      this.setBackendHost(this.altHosts[Object.keys(this.altHosts)[domainIdx]]);
    },
    setBackendHost(host) {
      const erroredHostValues = [null, undefined, "null", "undefined"];
      if (host && erroredHostValues.every((err) => host !== err)) {
        localStorage.setItem("backendHost", host);
      } else {
        this.companyInputRequired = true;
      }
    },
  },
  getters: {},
  computed: {
    browserSupported() {
      return navigator.userAgent.indexOf("Trident") === -1;
    },
    disableLoginButton() {
      return this.companyInputRequired && !this.companyInput;
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  &-active {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &-logo {
      height: 6rem;
    }

    &-header {
      padding: 2rem 2rem 1rem;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      &-title {
        color: #303032;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 900;
      }

      &-subtitle {
        color: #60666b;
        text-align: center;
      }
    }

    &-btn {
      background: #115efb;
      color: white;
      font-weight: 600;
      width: fit-content;
      padding: 0.8rem 1.5rem;
      border-radius: 999rem;
      user-select: none;
      cursor: pointer;

      &.disabled {
        background: #8ca8fd;
        pointer-events: none;
      }
    }

    &-company {
      padding: 0.5rem 0;
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      align-items: center;

      &-header {
      }

      &-input {
        height: 2rem;
        padding-left: 1rem;
        width: 100%;
        box-sizing: border-box;

        border: 2px solid rgba(#000, 8%);
        border-radius: 180px !important;

        background-color: #f1f2f3;
        transition: all 0.3s ease-in-out;

        font-size: 1rem;

        &:hover {
          background-color: #f5f6f8;
        }

        &:focus,
        :focus-visible,
        :focus-within {
          outline: none;
          border: 2px solid #115efb;
          background-color: #ffffff;
        }

        &[placeholder] {
          text-overflow: ellipsis;
        }
      }
    }

    &-unsupported {
      padding: 0 2rem;
      text-align: center;
      color: #8f9399;
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;

      &-img {
        height: 2.5rem;
      }
    }
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 1.5rem;
    &-grey {
      color: #8f9399;
      padding: 0.4rem 0;
      text-align: center;
    }

    &-contact {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      &-link {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;
        color: #115efb;
        & .icon {
          height: 1.2rem;
          margin-right: 0.3rem;
        }
      }
    }
  }
}
</style>
