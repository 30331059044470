export default {
  name: "IntercomMixin",

  data() {
    return {
      appId: "vp10oulo",
    };
  },

  computed: {
    userFullName() {
      return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
    },
    intercomEnabled() {
      return !!this.currentUser?.uuid && !!this.currentWorkspace?.uuid;
    },
  },

  watch: {
    intercomEnabled(newVal) {
      if (newVal) {
        this.setIntercomSettings();
        this.startIntercom();
      }
    },
  },

  mounted() {
    if (this.intercomEnabled) {
      this.setIntercomSettings();
      this.startIntercom();
    }
  },

  methods: {
    setIntercomSettings() {
      window.intercomSettings = {
        app_id: this.appId,
        user_id: this.currentUser.uuid,
        name: this.userFullName, // Full name
        email: this.currentUser.username, // Email address
        created_at: this.currentUser.date_joined, // Signup date as a Unix timestamp
        user_hash: this.currentUser.intercom_user_hash,
        company: {
          company_id: this.currentWorkspace.uuid,
          name: this.currentWorkspace.name,
          user_count: this.currentWorkspace.member_count,
          date_created: new Date(this.currentWorkspace.date_created).valueOf(),
        },
      };
    },
    startIntercom() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };

        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };

        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/vp10oulo";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };

        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    },
  },
};
