<template>
  <div class="empty">
    <img src="@a/logo.svg" alt="" class="empty-img" />
    <p class="empty-text">It seems like you aren't a part of any workspaces.</p>
    <p class="empty-text">
      If this shouldn't be the case, contact your workspace administrator.
    </p>
    <p class="empty-logout" @click="logout">Back to login</p>
    <div class="empty-copy">
      <p id="test" class="greyed sub">
        If you don't have a uman account yet, contact your workspace
        administrator to receive your invitation.
      </p>
      <p class="greyed sub">or find out more about us here</p>
      <div class="contact">
        <p class="contact-info">
          <a href="https://uman.ai" class="contact-info-link">
            <img src="@a/icons/web.svg" alt="" class="icon" />
            uman.ai
          </a>
        </p>
        <p class="contact-info">
          <a href="mailto:hello@uman.ai" class="contact-info-link">
            <img src="@a/icons/email.svg" alt="" class="icon" />
            hello@uman.ai
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "WorkspacesEmpty",
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style scoped lang="scss">
.empty {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  &-img {
    height: 3rem;
  }

  &-text {
    padding: 0.5rem 2rem;
    text-align: center;
  }

  &-logout {
    width: fit-content;
    padding: 1rem 2rem;
    font-weight: 600;
    background: black;
    color: white;
    margin-top: 2rem;
    border-radius: 999rem;
    cursor: pointer;
    user-select: none;
  }

  &-copy {
    padding: 5rem 2rem;
    & .greyed {
      color: #8f9399;
      padding: 0.4rem 0;

      &.sub {
        text-align: center;
      }
    }
    & .contact {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      &-info {
        &-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          text-decoration: none;
          color: #115efb;
          & .icon {
            height: 1.2rem;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
