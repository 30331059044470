<template>
  <div class="avatar">
    <img
      v-if="currentUser.avatar"
      :src="currentUser.avatar"
      alt=""
      class="avatar-img"
      @click="open = !open"
    />
    <span
      v-else
      class="avatar-img"
      @click="open = !open"
      :style="{ backgroundColor }"
    >
      {{ initials }}
    </span>
    <div v-if="open" class="avatar-menu-container" @click="open = false">
      <div class="avatar-menu" @click.stop>
        <p class="avatar-menu-username">
          Logged in as
          <span class="avatar-menu-username-name">{{ fullName }}</span>
        </p>
        <div class="avatar-menu-btns">
          <p class="avatar-menu-btns-app btn" @click="handleAppLinkClicked()">
            Go to app
            <img
              src="@a/icons/link.svg"
              alt=""
              class="avatar-menu-btns-app-icon"
            />
          </p>
          <p class="avatar-menu-btns-logout btn" @click="logout">Log out</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { userColor } from "../util";

export default {
  name: "AvatarDropdown",
  data: () => ({
    open: false,
    changeWorkspace: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    fullName() {
      return this.currentUser.first_name && this.currentUser.last_name
        ? `${this.currentUser.first_name} ${this.currentUser.last_name}`
        : this.currentUser.username;
    },
    initials() {
      return `${
        this.currentUser.first_name?.[0] || this.currentUser.username[0]
      }${this.currentUser.last_name?.[0] || ""}`.toUpperCase();
    },
    backgroundColor() {
      return userColor(this.currentUser);
    },
  },
  methods: {
    ...mapActions(["logout", "setToast"]),
    handleAppLinkClicked() {
      const backendHost = localStorage.getItem("backendHost");
      const appUrl = backendHost.replace("https://api.", "https://app.");
      window.open(appUrl, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: default;
}

.avatar-img {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100%;
  z-index: 96;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 900;
  color: white;
  user-select: none;
}

.avatar-menu {
  background: white;
  border-radius: 5px;
  border: 1px solid #00000020;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 99;

  display: flex;
  flex-flow: column nowrap;
  padding: 0.2rem;

  &-username {
    padding: 0.5rem 1rem;

    &-name {
      font-weight: 600;
    }
  }

  &-settings {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background: rgba(#000, 4%);
    margin-bottom: 8px;
    font-size: 0.8rem;

    &-header {
      font-weight: 600;
      padding-bottom: 0.5rem;
    }

    &-setting {
      display: grid;
      grid-template-columns: auto 35px;
      grid-template-columns: auto 35px;

      .setting-info {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.2rem;

        .setting-name {
        }

        .setting-desc {
          color: #4f4f4f;
        }
      }

      .setting-toggle {
      }
    }
  }

  &-btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid rgba(#000, 16%);

    .btn {
      width: fit-content;
      padding: 0.5rem 1rem;
      border-radius: 999rem;
      cursor: pointer;
      color: white;
      font-weight: 600;
      text-align: center;
    }

    &-app {
      background: black;

      &-icon {
        height: 1rem;
        filter: invert(1);
      }
    }

    &-logout {
      background: red;
    }
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 4rem 0.5rem 0.5rem 0.5rem;
    background: #ffffffaa;
    z-index: 95;
  }
}
</style>
