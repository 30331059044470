<template>
  <div class="header">
    <AvatarDropdown id="avatardropdown" class="header-avatar" />
    <SearchBar class="header-searchbar" />
    <WorkspaceDropdown class="header-workspace" />
  </div>
</template>

<script>
import SearchBar from "./SearchBar.vue";
import AvatarDropdown from "../atoms/AvatarDropdown.vue";
import WorkspaceDropdown from "../atoms/WorkspaceDropdown.vue";

export default {
  name: "SearchPageHeader",
  components: {
    SearchBar,
    AvatarDropdown,
    WorkspaceDropdown,
  },
};
</script>

<style scoped lang="scss">
.header {
  display: grid;
  grid-template-rows: 3rem 3rem;
  grid-template-columns: 3rem auto 3rem;
  grid-template-areas:
    "avatar workspace ."
    "searchbar searchbar searchbar";
  align-items: center;

  &-img {
    width: 1.5rem;
    border-radius: 100%;
    cursor: pointer;
  }

  &-workspace {
    grid-area: workspace;
  }

  &-avatar {
    grid-area: avatar;
  }

  &-searchbar {
    grid-area: searchbar;
  }
}
</style>
