import Console from "@/console";
import store from "@/store";
import { getClient } from "../axios";

const client = getClient("/users");

export const eventType = {
  file_click_through: "file_click_through",
  insert: "insert",
  inspect: "inspect",
  view: "view",
  list: "list",
};

export const searchTrigger = {
  search: "search",
  slide_change: "slide_change",
};

export const searchDetail = {
  search: {
    input: "input",
  },
  slide_change: {
    switch_slide: "switch slide",
    current_slide_change: "current slide change",
    refresh: "refresh",
  },
};

/**
 * @param {object} event has to contain:
 * - subresource_trace_id
 * - resource_trace_id
 */
export async function sendClickThroughEvent(event) {
  try {
    const workspaceId = store.getters.currentWorkspace.uuid;
    const userId = store.getters.currentUser.uuid;
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        search_id: store.getters.lastSearchId,
        event: eventType.file_click_through,
        tab: "all",
        page: "search_results",
        context: "subresource",
        date_created: new Date().toISOString(),
      },
    ]);
  } catch (error) {
    Console.debug(
      "Something went wrong when sending a clickthrough event",
      error
    );
  }
}

/**
 * @param {object} event has to contain:
 * - subresource_trace_id
 * - resource_trace_id
 */
export async function sendInsertEvent(event) {
  try {
    const workspaceId = store.getters.currentWorkspace.uuid;
    const userId = store.getters.currentUser.uuid;
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        search_id: store.getters.lastSearchId,
        event: eventType.insert,
        tab: "all",
        context: "subresource",
        page: "search_results",
        date_created: new Date().toISOString(),
      },
    ]);
  } catch (error) {
    Console.debug("Something went wrong when sending a insert event", error);
  }
}

/**
 * @param {object} event has to contain:
 * - subresource_trace_id
 * - resource_trace_id
 */
export async function sendInspectEvent(event) {
  try {
    const workspaceId = store.getters.currentWorkspace.uuid;
    const userId = store.getters.currentUser.uuid;
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        search_id: store.getters.lastSearchId,
        event: eventType.inspect,
        tab: "all",
        page: "search_results",
        context: "subresource",
        date_created: new Date().toISOString(),
      },
    ]);
  } catch (error) {
    Console.debug("Something went wrong when sending a inspect event", error);
  }
}

/**
 * @param {array} events array with {subresource_trace_id, resource_trace_id, rank}
 */
export async function sendBatchViewEvent(event) {
  try {
    const workspaceId = store.getters.currentWorkspace.uuid;
    const userId = store.getters.currentUser.uuid;
    await client.post(
      `/${userId}/events/`,
      event.ids.map((entry) => ({
        workspace_id: workspaceId,
        resource_trace_id: entry.resource_trace_id,
        subresource_trace_id: entry.subresource_trace_id,
        rank: entry.rank,
        search_id: store.getters.lastSearchId,
        event: eventType.view,
        tab: "all",
        context: "subresource",
        page: "search_results",
        date_created: new Date().toISOString(),
      }))
    );
  } catch (error) {
    Console.debug("Something went wrong when sending a view event", error);
  }
}

/**
 * @param {array} events array with {subresource_trace_id, resource_trace_id, rank}
 */
export async function sendBatchListEvent(event) {
  try {
    const workspaceId = store.getters.currentWorkspace.uuid;
    const userId = store.getters.currentUser.uuid;
    await client.post(
      `/${userId}/events/`,
      event.ids.map((entry) => ({
        workspace_id: workspaceId,
        resource_trace_id: entry.resource_trace_id,
        subresource_trace_id: entry.subresource_trace_id,
        rank: entry.rank,
        search_id: store.getters.lastSearchId,
        event: eventType.list,
        tab: "all",
        context: "subresource",
        page: "search_results",
        date_created: new Date().toISOString(),
      }))
    );
  } catch (error) {
    Console.debug("Something went wrong when sending a list event", error);
  }
}
