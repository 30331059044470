<template>
  <div
    class="add"
    @click.stop="insertSlide"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <img src="@a/icons/add.svg" alt="" class="add-img" />
    <p v-if="hovered" class="add-tooltip">Add slide to presentation</p>
  </div>
</template>

<script>
import { base64ArrayBuffer } from "../../util";
import { mapActions, mapState } from "vuex";
import Console from "@/console";
import { sendInsertEvent } from "@/store/services/feedbackService";

export default {
  name: "AddButton",
  props: {
    slide: {
      type: Object,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    hovered: false,
  }),
  computed: {
    ...mapState({
      currentWorkspace: (state) => state.workspaces.currentWorkspace,
      lastSearchId: (state) => state.resources.lastSearchId,
    }),
  },
  methods: {
    ...mapActions(["getSlideURL", "setToast"]),
    getCurrentSlideId() {
      return new Promise((resolve, reject) => {
        window.Office.context.document.getSelectedDataAsync(
          window.Office.CoercionType.SlideRange,
          function (asyncResult) {
            if (asyncResult.status == "failed") {
              reject("getting current slide info failed");
            } else {
              resolve(asyncResult.value.slides[0].id);
            }
          }
        );
      });
    },
    async sendFeedback() {
      sendInsertEvent({
        subresource_trace_id: this.slide.trace_id,
        resource_trace_id: this.slide.references[0].resource.trace_id,
        rank: this.rank,
      });
    },
    async insertSlide() {
      try {
        this.sendFeedback();
        // Step 1: Getting current slide id
        let slideId = undefined;
        try {
          slideId = await this.getCurrentSlideId();
        } catch (e) {
          Console.debug("Error during slide insert", e);
        }

        // Step 2: Get signed url of slide & slide id of target slide
        this.$emit("insertProgress", 8);
        const { signed_url, source_id } = await this.getSlideURL({
          workspace: this.currentWorkspace.uuid,
          slide: this.slide.uuid,
        });

        // Step 3: Download slide, convert it to base64
        this.$emit("insertProgress", 16);
        const base64 = await this.getSlideBase64(signed_url);

        // Step 4: Insert
        this.$emit("insertProgress", 20);
        await window.PowerPoint.run(async (context) => {
          try {
            context.presentation.insertSlidesFromBase64(base64, {
              targetSlideId: `${slideId}#`,
              sourceSlideIds: [`${source_id}#`],
            });
            await context.sync();
            this.$emit("insertProgress", 100);
          } catch (e) {
            console.error("Something went wrong during slide insertion", e);
            this.$emit("insertProgress", -1);
          }
        });
      } catch (e) {
        console.error("Something went wrong during slide insertion", e);
        this.$emit("insertProgress", -1);
      }
    },

    async getSlideBase64(url) {
      // Step 3.1: Download slide
      const res = await fetch(url);
      const buf = await res.arrayBuffer();

      // Step 3.2: Get base64 of slide
      return base64ArrayBuffer(buf);
    },
  },
};
</script>

<style scoped lang="scss">
.add {
  &-tooltip {
    position: absolute;
    top: 2rem;
    right: 0;
    background: #2b2b2b;
    color: white;
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    white-space: nowrap;

    &::before {
      content: "";
      height: 0.3rem;
      width: 0.5rem;
      background: #2b2b2b;
      position: absolute;
      top: -0.3rem;
      right: 0.5rem;
      clip-path: polygon(0 100%, 50% 0%, 100% 100%);
    }
  }

  &-img {
    height: 1.5rem;
  }
}
</style>
